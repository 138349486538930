/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import CloseSvg from '@icons/close.svg';
import LogoSvg from '@icons/logo.svg';
import mq from '@mq';

const HomePage = () => {
  const [textVisible, setTextVisible] = useState(false);

  const toggleExpand = useCallback(() => {
    setTextVisible(previous => !previous);
  }, []);

  return (
    <Main>
      <Video muted autoPlay src="/animation.mp4" textVisible={textVisible} />
      <Logo />

      <LogoContainer>
        <InfoButtonContainer>
          <InfoButton onClick={toggleExpand}>info</InfoButton>
        </InfoButtonContainer>
      </LogoContainer>
      <Text textVisible={textVisible}>
        <div>
          <div>Telos Pictures UG</div>
          <div>Lindleystraße 4</div>
          <div>60314 Frankfurt</div>
        </div>
        <div>
          <a href="mailto:info@telospictures.de">info@telospictures.de</a>
        </div>
        <div>
          <div>Amtsgericht:</div>
          <div>Frankfurt Main HRB 124783</div>
        </div>
        <div>
          <div>Geschäftsführender Gesellschafter:</div>
          <div> Andro Steinborn</div>
        </div>
        <CloseButton onClick={toggleExpand} />
      </Text>
    </Main>
  );
};
const Main = styled.main``;

const Video = styled.video`
  display: none;

  ${mq.small} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
    transition: opacity 150ms;
    opacity: ${props => (!props.textVisible ? 1 : 0)};
  }
`;

const LogoContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: flex-end;
  background-color: var(--color-background);
  z-index: 1;
  bottom: 80px;

  ${mq.small} {
    bottom: 160px;
  }
`;

const CloseButton = styled(CloseSvg)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 80px;
  cursor: pointer;

  ${mq.small} {
    bottom: 160px;
  }
`;

const underline = keyframes`
  from {
    background-size: 0% 2px;
  }

  to {
    background-size: 100% 2px;
  }
`;

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const InfoButtonContainer = styled.div`
  opacity: 0;
  animation: ${appear} 1s 1s forwards;

  ${mq.small} {
    animation: ${appear} var(--motion-triple) 5.2s forwards;
  }
`;

const InfoButton = styled.div`
  text-transform: uppercase;
  cursor: pointer;
  background-image: linear-gradient(to right, var(--color-white), var(--color-white));
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 2px;
  transition: background-size 0.5s ease;
  clip-path: inset(0px 2.6px 0px 1.5px);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      animation: ${underline} 600ms;
    }
  }
`;

const Text = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background-color: var(--color-background);
  z-index: 1;
  text-align: center;
  display: grid;
  row-gap: 1em;
  transform: translate3d(0, ${props => (props.textVisible ? 0 : `100vh`)}, 0);
  transition: transform 300ms;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;

  a {
    background-image: linear-gradient(to right, var(--color-white), var(--color-white));
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    transition: background-size 0.5s ease;
    clip-path: inset(0px 2.6px 0px 1.5px);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        animation: ${underline} 600ms;
      }
    }
  }
`;

const Logo = styled(LogoSvg)`
  ${mq.small} {
    display: none;
  }

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  opacity: 0;
  animation: ${appear} 2s forwards;
`;

export default HomePage;
